<template>
  <div class="col-xl-4 col-llg-6 col-lg-6 col-md-6 col-sm-12 product_col" :class="['pr_'+product_code, product_category]" :data-price='product_code'>
      <div class="card card-developer-meetup cardtableview" :data-price='product_code' :id='product_code' :class="zeroproductimg">
          <div class="bg_light_primary rounded-top text-center">
          <img style="width:100%;max-width:250px;object-fit:contain;padding:20px;" :src='product_img' alt="product logo" height="150">
          </div>
          <div class="card-body">
              <div class="meetup-header d-flex align-items-center">
                  <div class="meetup-day">
                  <h3 class="mb-1">{{product_price}} {{product_currency}}</h3>
                  <input v-if="!zeropricebtn" :id="'button-tab'+product_code" :name="'tab'+product_code" type="button" value="Select" class="btn btn-success font-bold buttonPrice" :data-product='product_code' :data-productname='product_name' :data-productprice='product_price' :data-productcategory='product_category' @click='$emit("selectProductChange", $event.target)'>
                  </div>
                  <div class="my-auto">
                  <h4 class="card-title mb-25">
                    <svg style="font-size:18px;" v-b-tooltip.hover.top='product_description' data-v-6f62cb96="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-info"><path data-v-6f62cb96="" fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path></svg> 
                    {{product_name}}
                  </h4>
                  <p class="card-text mb-0">{{product_short_description}} </p>
                  <div style="margin-top:5px;">
                    <b-form-checkbox
                      @change="storageOfferName()"
                      :id="'offerpr_'+product_code"
                      :value="product_code"
                      :data-price="product_price"
                      name="products_arr[]"
                    >
                      Add to offer
                    </b-form-checkbox>
                  </div>
                  </div>
              </div>
              <div class=" showmorecoverages_bg showmorecovsclassic" @click="primarylist_show=!primarylist_show">
                <h6 class="titleprotable">Primary Coverages</h6>
                <font-awesome-icon icon="fa-solid fa-chevron-up" class="mtt-1 fascovs ml-1" size="lg" v-if="primarylist_show"/>
                <font-awesome-icon icon="fa-solid fa-chevron-down" class="mtt-1 fascovs ml-1" size="lg" v-else/>
              </div>
              <main class="mt-1" v-if="primarylist_show">
                <div class="media" v-for="p in primary" :key="p.index">
                    <div class="media-aside mr-1 align-self-start">
                    <span class="b-avatar badge-light-primary rounded" style="width: 34px; height: 34px;"><span class="b-avatar-custom"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg></span></span>
                    </div>
                    <div class="media-body">
                      <h6 class="mb-cst"><span class="listcoversspan">Code: </span>{{p.coverage_code}}</h6>
                      <h6 class="mb-cst"><span class="listcoversspan">Excess: </span>{{p.coverage_excess}}</h6>
                      <h6 class="mb-cst"><span class="listcoversspan">Limit: </span>{{p.coverage_limit}}</h6>
                    </div>
                </div>
              </main>
              <div class="showmorecoverages_bg showmorecovsclassic my-1" @click="secondarylist_show=!secondarylist_show">
                <div class="d-flex align-items-center">
                  <h6 class="titleprotable">Secondary Coverages</h6>
                  <font-awesome-icon icon="fa-solid fa-chevron-up" class="mtt-1 fascovs ml-1" size="lg" v-if="secondarylist_show"/>
                  <font-awesome-icon icon="fa-solid fa-chevron-down" class="mtt-1 fascovs ml-1" size="lg" v-else/>
                </div>
              </div>
              <main v-if="secondarylist_show" >
                <div class="media" v-for="s in secondary" :key="s.index">
                  <div class="media-aside mr-1 align-self-start">
                      <span class="b-avatar badge-light-primary rounded" style="width: 34px; height: 34px;"><span class="b-avatar-custom"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span></span>
                  </div>
                  <div class="media-body">
                      <h6 class="mb-cst"><span class="listcoversspan">Code: </span>{{s.coverage_code}}</h6>
                      <h6 class="mb-cst"><span class="listcoversspan">Excess: </span>{{s.coverage_excess}}</h6>
                      <h6 class="mb-cst"><span class="listcoversspan">Limit: </span>{{s.coverage_limit}}</h6>
                  </div>
                </div>
              </main>
              <div role="group" class="b-avatar-group pt-2">
                <div style='display: flex;justify-content: center;align-items: center;width: 100%;' v-if='product_documents[product_code]'>
                  <a style='margin:0 10px;border-radius:6px;' :href="product_documents[product_code].tob_link" target="_blank" class="btn btn-sm btn-outline-secondary tooltipwithdescription" v-b-tooltip.hover :title="product_documents[product_code].tob_title">{{ product_code.includes('-3') ? 'SCH' : 'TOB' }}</a>
                  <a style='margin:0 10px;border-radius:6px;' :href="product_documents[product_code].pwo_link" target="_blank" class="btn btn-sm btn-outline-secondary tooltipwithdescription" v-b-tooltip.hover :title="product_documents[product_code].pwo_title">PWO</a>
                  <a style='margin:0 10px;border-radius:6px;' :href="product_documents[product_code].ipid_link" target="_blank" class="btn btn-sm btn-outline-secondary tooltipwithdescription" v-b-tooltip.hover :title="product_documents[product_code].ipid_title">IPID
                  </a>
                </div>
              </div>  
          </div>
      </div>
  </div>
</template>

<script>
import { product_documents, viewsProductsMixin } from '@/products';
import {
  VBTooltip,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
export default {
  mixins: [viewsProductsMixin],
  components: {
    BFormCheckbox,
    BButton,
    VBTooltip
  },
  props:['product_price','product_currency','product_currency_symbol','product_category','product_code','product_description','product_name','product_short_description','primary','secondary','resCheckName','destCheckName','startDate','daysNum','agesArr'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      zeroproductimg: null,
      zeropricebtn: false,
      primarylist_show: true,
      secondarylist_show: false,
      product_documents,
      product_img: null,
      col_img: require('@/assets/images/quotation/globeinsure.png'),
      sompo_img: require('@/assets/images/quotation/sompo.png'),
      mon_img: require('@/assets/images/quotation/mondial.png'),
      kem_img: require('@/assets/images/quotation/kemter.png'),
      battle_img: require('@/assets/images/quotation/battleface.png'),
    }
  },
  created() {
    this.setImg();
  },
  mounted() {
    this.checkProducts(".pr_");
    this.zeroProducts();
  }
}
</script>